.portfolio {
  grid-column: content-start / content-end;
  grid-row: portfolio-start / portfolio-end;

  display: flex;
  align-items: center;
  @media only screen and (max-width: $bp-medium) {
    // padding-top:18rem;
    flex-direction: column;
    align-items: start;
    justify-content: center;
 
  }
  @media only screen and (max-width: $bp-small) {
    align-items: center;
    grid-column: full-start / full-end;
 
   }

  &__container {
    display: flex;
    position: relative;

    // @media only screen and (max-width: $bp-medium) {
    //   flex-wrap: wrap;
    //   justify-content: center;
    //   align-items: center;

    //   gap: 2rem;
    // }
    @media only screen and (max-width: $bp-small) {
      align-items: center;
      flex-direction: column;
      justify-content: center;  
    }
  }

  h1 {
    @media only screen and (max-width: $bp-medium) {
      padding-bottom: 3rem;
     
    }
    @media only screen and (max-width: $bp-small) {
      padding-top: 8.4rem;
      padding-bottom: 4rem;
      align-self: start;
    }
  }

  &__button {
    transform: rotate(90deg);
    position: absolute;
    right: -8rem;
    bottom: 4rem;
    font-size: 1.3rem;
    cursor: pointer;
    padding: 1.5rem;

    @media only screen and (max-width:$bp-small){
      transform: rotate(0);
      // right:-4rem;
      // bottom:-8rem;
      position: static !important;
      align-self: flex-end;
     
      
    }
  
    
  .link{
      display: flex;
      align-items: center;
      gap: 0.5rem;
      text-decoration: none;
      color:var(--color-black);
    }
    path{
      fill:var(--color-black);
    }
    &:hover{
      .link{
        color:#EA4C89;
      }
      path{
        fill:#EA4C89 !important;
      }
    }
  }
}

.project {
  margin: 0 1.5rem;
  transition: transform 0.3s;
 
@media only screen and (max-width: $bp-large) {
    margin: 0 0.5rem;
  }

  @media only screen and (max-width: $bp-medium) {
  }
  &__image {
    object-fit: cover;
    
    width: 90%;
    border:1.3rem solid #fff;
    box-shadow: 0px 24px 40px rgba(0, 0, 0, 0.1);

  
    @media only screen and (max-width: $bp-medium) {
     height:20rem;
    }

    @media only screen and (max-width: $bp-small) {
      height: 32rem;
      width: 36rem;
    }
    box-shadow: 0px 24px 40px rgba(0, 0, 0, 0.1);
  }
  &:hover {
    transform: scale(1.03);
  }
}
