.header {
  grid-row: header-start / header-end;
  grid-column: content-start / content-end;

  display: flex;
  align-items: center;
  margin-top: -8.4rem;

  h1{
    color:var(--color-gray-light);
    span{
      color:var(--color-black);
    }

    @media only screen and (max-width: $bp-medium) {
      width:100%;
    }
  }

  @media only screen and (max-width: $bp-medium) {
    flex-direction: column;
    justify-content: flex-end;
    align-items: start;
  
    &__image-container {
      align-self: center;
    }
    
  }
  @media only screen and (max-width:$bp-small){
    grid-column: full-start / full-end;
    justify-content: center;
  }
 
  &__dropdown {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    svg {
    }
  }
}
