.navbar {
  height: 8.4rem;

  background: #f4f4f4;
  width: 100%;
  position: sticky;
  top: 0;
  grid-row: navbar-start / navbar-end;
  grid-column: navigation-start / navigation-end;

  transition: height 0.3s;
  z-index: 60000;

  @media only screen and (max-width: $bp-small) {
    grid-column: full-start / full-end;
  }

  &-active {
    @media only screen and(max-width:$bp-small) {
      height: 100vh;
      width: 100%;
    }
  }

  &__content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    width: 2.8rem;
    height: 2.8rem;

    &-link {
      @media only screen and(max-width:600px) {
        align-self: flex-start;
        margin-top: 3rem;
      }
    }

  }

  &__list {
    display: flex;
    list-style: none;

    @media only screen and(max-width:600px) {
      flex-direction: column;
    }

    &-item {
      color: var(--color-gray-light);
      padding-left: 3rem;

      @media only screen and(max-width:600px) {
        padding: 1rem 0;
      }
    }
  }

  &__link {
    font-weight: 600;
    font-size: 1.6rem;
    color: var(--color-gray-light);
    text-decoration: none;

    @media only screen and(max-width:600px) {
      font-size: 4.8rem;
    }

    &-active {
      color: var(--color-black);
    }

    &:hover {
      color: var(--color-black);
    }
  }

  .navbar__icon {
    cursor: pointer;
    padding-top: 1rem;
    align-self: flex-start;

    margin-top: 2.4rem;

    &-bar {
      width: 20px;
      height: 2px;
      background-color: #333;
      margin: 4px 0;
      transition: 0.4s;
    }

    &-active .bar1 {
      -webkit-transform: rotate(-45deg) translate(-9px, 6px);
      transform: rotate(-45deg) translate(-1px, 3px);
    }

    &-active .bar3 {
      -webkit-transform: rotate(45deg) translate(-8px, -8px);
      transform: rotate(45deg) translate(-1px, -3px);
    }
  }
}

.navigation__icon-active .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-1px, 3px);
}

.navigation__icon-active .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-1px, -3px);
}

.display-hidden-sm {
  @media only screen and(max-width: 600px) {
    display: none;
  }
}

.display-hidden-above-sm {
  @media only screen and(min-width: 600px) {
    display: none;
  }
}

.button {
  text-decoration: none;
  color: #fff;
  background: var(--color-black);
  border-radius: 0.3rem;
  padding: 1rem 2.2rem;
  font-size: 1.6rem;
  font-weight: 600;

  @media only screen and (max-width: $bp-small) {
    position: absolute;
    bottom: -6rem;
    right: 2rem;
  }
}