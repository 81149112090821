@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

:root {
  --color-gray-light: #bdbdbd;
  --color-black: #222;
}
$bp-large: 75em; // 1200 px
$bp-medium: 56.25em; // 800px
$bp-small: 37.5em; // 600px
$bp-smallest: 25em; // 400px

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%; // 10xp = 1rem
  font-family: 'Montserrat', sans-serif;
  color: var(--color-black);
   scroll-behavior: smooth;

  @media only screen and (max-width: $bp-small) {
    font-size: 50%;
  }
}
body::-webkit-scrollbar {
  display: none;
}
body::selection {
  background: pink;
  color: yellow;
}
body {
 min-height: 100vh;
  background:#f4f4f4;
}
.App {
  display: grid;
  grid-template-columns:
    [full-start] 2.4rem[navigation-start] 1fr
    [content-start]repeat(10, 1fr) [content-end] 1fr[navigation-end] 2.4rem
    [full-end];
  grid-template-rows:
    [navbar-start]8.4rem [navbar-end header-start]calc(100vh - 8.4rem)
    [header-end portfolio-start] 100vh [portfolio-end resume-start] 100vh [resume-end contact-start] 100vh [contact-end];
  grid-column-gap: 2.4rem;

  @media only screen and (max-width:$bp-medium){
    // grid-template-rows:
    // [navbar-start]8.4rem [navbar-end header-start]calc(100vh - 8.4rem)
    // [header-end space-between-1-start] 3rem[space-between-1-end portfolio-start] 100vh [portfolio-end resume-start] 100vh [resume-end contact-start] 100vh [contact-end];
  }

  @media only screen and (max-width: $bp-small) {
    padding: 0 2rem;

    grid-template-rows:
      [navbar-start]8.4rem [navbar-end header-start]100vh
      [header-end portfolio-start] min-content [portfolio-end section-break-1-start] 4rem [section-break-1-end resume-start] min-content [resume-end contact-start] 100vh [contact-end footer-start] 4.2rem[footer-end];
  }
}

.portfolio-container{
  display: grid;
  grid-template-columns:
    [full-start] 2.4rem[navigation-start] 1fr
    [content-start]repeat(10, 1fr) [content-end] 1fr[navigation-end] 2.4rem
    [full-end];

    grid-template-rows: [navbar-start]8.4rem [navbar-end content-start]min-content [content-end footer-start] 4.2rem [footer-end];

    @media only screen and (max-width: $bp-small) {
      padding: 0 2rem;}
}

.scroll-position {
  grid-column: navigation-start / content-start;
  grid-row: 2 / -1;

  &__content {
    position: sticky;
    top: 50%;
    transform: translateY(-40%);

    display: flex;
    flex-direction: column;

    width: 100%;
    margin-bottom: 4.2rem;
    row-gap: 1.2rem;
  }

  &__item {
    height: 0.8rem;
    width: 0.8rem;
    border-radius: 50%;
    background: var(--color-gray-light);
    &-active {
      background: var(--color-black);
    }
  }
}

.heading-h1 {
  font-size: 4.8rem;
  width: 90%;

  @media only screen and (max-width: $bp-large) {
    font-size: 3.6rem;
  }
}

.display-hidden {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}
