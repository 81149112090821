.portfolio-page {
  min-height:100vh;
  width: 100%;
  grid-column: navigation-start / navigation-end;
  grid-row:content-start / content-end;


  &__button {
    display: flex;
    font-size: 1.3rem;
    cursor: pointer;

    align-items: center;
  }
  &__container {
    margin-top:2rem;
    padding:0 4rem;
    display: grid;
    grid-template-columns: repeat(4,  1fr);

    gap:30px;

    @media only screen and (max-width:$bp-medium){
      grid-template-columns: repeat(2,  1fr);
 }
 @media only screen and (max-width:$bp-small){
   grid-template-columns: 1fr;
 }
  }

  &-item{
    img{
      border:1.3rem solid #fff;
      width:100%;
      height:auto;
    }
  }
}
