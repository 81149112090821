.contact {
  grid-row: contact-start / contact-end;
  grid-column: content-start / content-end;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 8.4rem;

  h1 {
    width: 60%;
  }

  &__form-container {
    width: 35%;

    @media only screen and (max-width: $bp-small) {
      width: 100%;
    }

  }

  &__form {
    width: 100%;
    position: relative;

    &-input-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 5rem;
      width: 100%;
      border-bottom: 1px solid var(--color-black);
    }

    input {
      background: #f4f4f4;
      font-family: 'Montserrat', sans-serif;
      outline: none;
      border: none;
      width:100%;

      &::placeholder {
        font-size: 1.6rem;
        font-weight: 600;
        color: var(--color-gray-light);
      }
    }

    &-icon {
      margin-left: -1rem;
    }

    @media only screen and (max-width: $bp-small) {
      padding-top: 8rem;
      width: 80%;
    }

    @media only screen and (max-width: $bp-smallest) {
      padding-top: 8rem;
      align-self: center;
      width: 100%;
    }
  }

  &__label,
  &__label {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  &__error {
    font-size: 1.1rem;
    margin-top: 0.4rem;
    float: left;
    color: red;
    font-weight: 600;
  }

  &__form-placement {
    font-size: 1.3rem;
    float: right;
    padding-top: 0.4rem;
  }

  .button {
    text-transform: uppercase;
    // position: absolute;
    // top: 0;
    // right: 0;
    margin-bottom: 1rem;
    padding: 0.5rem 1.5rem;
    

    @media only screen and (max-width: $bp-small) {
      padding: 2rem 0;
      bottom: -8rem;
      width: 100%;


      left: 0;
    }
  }

  @media only screen and (max-width: $bp-small) {
    grid-column: full-start / full-end;
    margin-top: 8rem;

    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding-top: 8.4rem;
  }
}