.footer {
  grid-column: navigation-start / navigation-end;
  grid-row: footer-start / footer-end;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
  position: sticky;
  bottom: 0;
  height: 4.2rem;

  @media only screen and(max-width:$bp-small) {
    grid-column: full-start / full-end;
    position: relative;
    justify-content: center;
  }

  &__text {
    font-size: 1.3rem;
    font-weight: 400;
  }
}
